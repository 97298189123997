"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";
import userState from "packages/state-management/state/user.state";
import LoadingScreen from "packages/web-ui/Cosmetic/LoadingScreen";

export default function RootPage() {
  const { user } = userState();
  const router = useRouter();

  useEffect(() => {
    if (!user.client_ready) return;
    router.push("onboard/login?page=get-number");
  }, [user.loggedIn, user.client_ready, user.username]);

  if (!user.client_ready) return <LoadingScreen />;

  return <pre>{JSON.stringify(user, null, 2)}</pre>;
}

/* return ( */
/*   <AuthRoute> */
/*     <div className={font.className}> */
/*       <NewHero/> */
/*       <WhyUs /> */
/*       <ExploreSection /> */
/*       <JoinCoursesSection /> */
/*       <ActivitiesSection /> */
/*       <TestimonialsSection /> */
/*       <MeetOurInstructors /> */
/*       <PartnerSection /> */
/*       <FAQComponent/> */
/*       <Footer /> */
/*     </div> */
/*   </AuthRoute> */
/* ); */
